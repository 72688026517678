export const pushStatusList = [
  {
    value: 'PUSHED',
    text: '已推送风火轮',
  },
  {
    value: 'PUBLISHED',
    text: '已风火轮发布',
  },
  {
    value: 'GENERATED_LINK',
    text: '已生成链接',
  },
  {
    value: 'PUBLISHED_BY_LINK',
    text: '已链接发布',
  },
  {
    value: 'PUSHED_EXPIRED',
    text: '推送风火轮-已过期',
  },
  {
    value: 'FHL_PUBLISHING',
    text: '风火轮发布中',
  },
];

export const statusList = [
  {
    value: 'ALL',
    text: '全部状态',
    number: 0,
  },
  {
    value: 'UNPUBLISH',
    text: '待发布',
    number: 0,
    color: '#5a5a5a'
  },
  {
    value: 'PUBLISHING',
    text: '发布中',
    number: 0,
    color: '#f9be71'
  },
  {
    value: 'PUBLISHED',
    text: '已发布',
    number: 0,
    color: '#a2d05c'
  },
];

export const channelList = [
  {
    value: 'ALL',
    text: '全部渠道',
    number: 0,
    tips: '门店销售发布笔记的渠道',
  },
  {
    value: 'FENGHUOLUN',
    text: '风火轮',
    number: 0,
  },
  {
    value: 'SHARE_PUBLISH_LINK',
    text: '分享链接',
    number: 0,
  },
  {
    value: 'OTHER',
    text: '其他',
    number: 0,
    tips: '<div>1、在风火轮内保存素材到本地后自行发布等非常规情况</div>2、待发布、已过期的笔记会暂时归到此类，销售进行发布操作后再切换成对应的渠道',
  },
];

export const noteTypeList = [
  {
    value: 'ALL',
    text: '全部类型',
    number: 0,
  },
  {
    value: 'IMAGE',
    text: '图文',
    number: 0,
  },
  {
    value: 'VIDEO',
    text: '视频',
    number: 0,
  },
];

export const columns = [
  {
    title: '笔记',
    align: 'center',
    width: 240,
    scopedSlots: { customRender: 'writtingPreview' },
  },
  {
    title: '状态',
    align: 'center',
    width: 85,
    scopedSlots: { customRender: 'status' },
  },
  {
    align: 'center',
    width: 90,
    title: '发布渠道',
    customRender: (record) => {
      if (record.publishStatus === 'UNPUBLISH' || record.publishStatus === 'EXPIRED') {
        return '-';
      }
      const rtText = channelList.find((v) => v.value === record.publishChannel)?.text;
      return rtText || record.publishChannel || '-';
    },
  },
  {
    align: 'center',
    width: 90,
    title: '笔记类型',
    dataIndex: 'noteType',
    customRender: (text) => {
      const rtText = noteTypeList.find((v) => v.value === text)?.text;
      return rtText || '-';
    },
  },
  {
    align: 'center',
    width: 120,
    title: '车系',
    scopedSlots: { customRender: 'carSeries' },
  },
  {
    title: '推送时间',
    sorter: true,
    width: 120,
    defaultSortOrder: 'descend',
    align: 'center',
    dataIndex: 'ctime',
    customRender: (text) => {
      return text || '-';
    },
  },
  {
    title: '发布时间',
    sorter: true,
    align: 'center',
    width: 120,
    scopedSlots: { customRender: 'publishTime' },
  },
  {
    title: '操作人',
    align: 'center',
    width: 100,
    dataIndex: 'actor',
    customRender: (text) => {
      return text || '-';
    },
  },
  {
    title: '创建人',
    align: 'center',
    width: 100,
    dataIndex: 'creator',
    customRender: (text) => {
      return text || '-';
    },
  },
  {
    title: '运营人',
    align: 'center',
    width: 100,
    dataIndex: 'masterAccountName',
    customRender: (text) => {
      return text || '-';
    },
  },
  {
    align: 'center',
    title: '操作',
    width: 160,
    fixed: 'right',
    scopedSlots: { customRender: 'operation' },
  },
];

export const detectColumns = [
  {
    title: '推送的笔记内容',
    align: 'center',
    width: 240,
    scopedSlots: { customRender: 'writtingPreview' },
  },
  {
    title: '检测时间',
    width: 120,
    align: 'center',
    dataIndex: 'detectTime',
    customRender: (text) => {
      return text || '-';
    },
  },
  {
    title: '检测结果',
    width: 210,
    align: 'center',
    scopedSlots: { customRender: 'detectMsg' },
  },
  {
    title: '笔记ID',
    align: 'center',
    width: 150,
    scopedSlots: { customRender: 'noteIdArr' },
  },
  {
    align: 'center',
    width: 120,
    title: '车系',
    scopedSlots: { customRender: 'carSeries' },
  },
  {
    title: '推送时间',
    // sorter: true,
    width: 120,
    // defaultSortOrder: 'descend',
    align: 'center',
    dataIndex: 'pushTime',
    customRender: (text) => {
      return text || '-';
    },
  },
  {
    title: '发布时间',
    // sorter: true,
    align: 'center',
    width: 120,
    scopedSlots: { customRender: 'publishTime' },
  },
  {
    title: '运营人',
    align: 'center',
    width: 100,
    dataIndex: 'operatorName',
    customRender: (text) => {
      return text || '-';
    },
  },
  {
    align: 'center',
    title: '操作',
    width: 160,
    fixed: 'right',
    scopedSlots: { customRender: 'operation' },
  },
];