<template>
  <div>
    <a-card v-show="currentStep === 1">
      <a-alert type="warning" show-icon message="推送的笔记可通过风火轮App、分享链接任一方式发布到小红书" />
      <div class="search_list">
        <div>
          <a-space>
            <div class="search_brand">
              <span
                :class="['brand_item', !searchForm.principalId ? 'active_item' : '']"
                @click="handleBrandClick(undefined)"
                >全部品牌</span
              >
              <span
                :class="['brand_item', searchForm.principalId === item.principalId ? 'active_item' : '']"
                v-for="item in allBrandList"
                :key="item.principalId"
                @click="handleBrandClick(item.principalId)"
                >{{ item.principalName }}</span
              >
              <span
                :class="['brand_item', searchForm.principalId === -1 ? 'active_item' : '']"
                @click="handleBrandClick(-1)"
                >无品牌</span
              >
            </div>
            <a-select
              style="width: 200px"
              v-model="searchForm.operatorGroupId"
              placeholder="请选择团队"
              show-search
              option-filter-prop="children"
              allowClear
              @change="getList(true)"
            >
              <a-select-option v-for="item in allTeamList" :key="item.id" :value="item.id">{{
                item.groupName
              }}</a-select-option>
            </a-select>

            <a-select
              v-model="searchForm.operatorId"
              style="width: 260px"
              placeholder="运营人"
              show-search
              option-filter-prop="children"
              @change="getList(true)"
              allowClear
            >
              <a-select-option v-for="item in allMemberList" :key="item.id" :value="item.id">{{
                item.operatorName
              }}</a-select-option>
            </a-select>
          </a-space>
        </div>

        <div style="margin: 10px 0px; padding-right: 10px; display: flex; justify-content: space-between">
          <SearchItemList
            v-model="searchForm.manayPublishStatus"
            :itemList="statusList"
            :showDefaultNum="false"
            @click="handleStatusClick"
          >
            <template v-slot:bottom="scopedData">
              <span class="item_number" v-if="scopedData.item.text !== '待发布' || !expiredNum">{{
                scopedData.item.number || 0
              }}</span>
              <span style="color: #6f6f6f" v-else>
                <span
                  :class="{ active_expired_item: !isExpired && searchForm.manayPublishStatus === 'UNPUBLISH' }"
                  @click.stop="handleExpiredClick(false)"
                  >全部 {{ scopedData.item.number }}</span
                >
                <span style="margin: 0 3px">|</span>
                <span
                  :class="{ active_expired_item: isExpired && searchForm.manayPublishStatus === 'UNPUBLISH' }"
                  @click.stop="handleExpiredClick(true)"
                  >待改时间 {{ expiredNum || 0 }}
                  <a-tooltip placement="top">
                    <template slot="title">
                      <div>因小红书限制，若当前距离定时发布时间不足 1 小时，销售无法发布。</div>
                      <div>此时需修改定时发布时间，建议修改为至少1.5小时后，或改为立即发布。</div>
                    </template>
                    <a-icon type="question-circle" />
                  </a-tooltip>
                </span>
              </span>
            </template>
          </SearchItemList>
          <SearchItemList v-model="searchForm.publishChannel" :itemList="channelList" @click="getList(true)" />
          <SearchItemList v-model="searchForm.noteType" :itemList="noteTypeList" @click="getList(true)" />
        </div>
        <div style="display: flex; justify-content: space-between">
          <div style="display: flex">
            <a-input-group compact>
              <a-select
                default-value="NOTE"
                style="width: 130px"
                v-model="searchForm.searchType"
                @change="searchForm.search = undefined"
              >
                <a-select-option value="NOTE"> 笔记标题 </a-select-option>
                <a-select-option value="AUTHOR"> 昵称/小红书号 </a-select-option>
                <a-select-option value="MEMBER"> 成员名称/编码 </a-select-option>
              </a-select>
              <a-input-search
                style="width: 180px; margin-right: 15px"
                placeholder="搜索"
                v-model.trim="searchForm.search"
                @search="getList(true)"
                allowClear
              ></a-input-search>
            </a-input-group>

            <a-input-group compact>
              <a-select
                default-value="PUBLISH_TIME"
                style="width: 125px"
                v-model="searchForm.timeRangeType"
                @change="checkTimeRange = [null, null]"
              >
                <a-select-option value="PUBLISH_TIME"> 发布时间 </a-select-option>
                <a-select-option value="PUSH_TIME"> 推送时间 </a-select-option>
              </a-select>
              <a-range-picker
                style="width: 220px"
                :placeholder="['开始时间', '结束时间']"
                :value="checkTimeRange"
                @change="onTimeChange"
                allowClear
              />
            </a-input-group>

            <a-tooltip placement="right" title="刷新数据">
              <a-button shape="circle" type="link" icon="sync" @click="getList()"></a-button>
            </a-tooltip>
          </div>
          <div>
            <a-badge :dot="showDot">
              <a-button @click="handleJumpToError">发布中自动检测异常</a-button>
            </a-badge>
          </div>
        </div>
      </div>
      <a-table
        :loading="tableLoading"
        :rowKey="(record, index) => index"
        :columns="columns"
        :scroll="{ x: 1380 }"
        :data-source="dataList"
        :pagination="false"
        @change="onTableChange"
      >
        <div slot="writtingPreview" slot-scope="text, record">
          <div class="writtingPreview">
            <div class="previewLeft" @click="openDrawer(record)">
              <img
                v-if="record.previewUrl"
                style="height: 100%; width: 100%; object-fit: cover"
                referrerpolicy="no-referrer"
                :src="record.previewUrl"
              />
            </div>
            <div class="previewRight">
              <div class="rightTitle" @click="openDrawer(record)">
                <a-popover>
                  <template slot="content">
                    <p>{{ record.noteTitle ? record.noteTitle : '' }}</p>
                  </template>
                  {{ record.noteTitle ? record.noteTitle : '-' }}
                </a-popover>
              </div>
              <template v-if="record.topicSelectionTagList && record.topicSelectionTagList.length > 0">
                <div>
                  <a-tag
                    :color="record.topicSelectionTagList[0] ? record.topicSelectionTagList[0].colorHex : 'blue'"
                    style="white-space: break-spaces"
                    >{{ record.topicSelectionTagList[0] ? record.topicSelectionTagList[0].tagName : '-' }}</a-tag
                  >
                  <a-tag v-if="record.topicSelectionTagList.length > 1" color="blue"
                    >+{{ record.topicSelectionTagList.length - 1 }}</a-tag
                  >
                </div>
              </template>
              <div class="rightXhs" v-show="record.nickname" style="display: flex" @click="handleJumpXHS(record)">
                <div class="logo">
                  <img src="@/assets/icon/xhs_logo.png" alt="" />
                </div>
                <div class="xhsId blue-text">{{ record.nickname || '-' }}</div>
              </div>
            </div>
          </div>
          <!-- <div v-else>
          <a-button v-if="record.noteLink" type="link" @click="openXhsNote(record.noteLink)">跳转笔记</a-button>
          <div v-else>-</div>
        </div> -->
        </div>
        <div slot="status" slot-scope="text, record">
          <div
            v-if="record.expiredStatus !== 'EXPIRED'"
            :style="`color: ${transStatusData(record.publishStatus).color}`"
          >
            {{ transStatusData(record.publishStatus).text }}
          </div>
          <div v-else style="color: #e65a6b">待发布-待改时间</div>
        </div>
        <div slot="carSeries" slot-scope="text, record">
          <div>{{ record.principalName || '-' }}</div>
          <div>{{ record.carSeriesName || '-' }}</div>
        </div>
        <div slot="publishTime" slot-scope="text, record">
          <div v-if="record.status == 'PUBLISHED' || record.status == 'PUBLISHED_BY_LINK'">
            {{ record.publishTime || '-' }}
          </div>
          <div v-else>
            <div v-if="record.postTime">
              <div style="font-size: 12px; user-select: none"><a-icon type="clock-circle" />定时发布</div>
              <div>
                {{ record.postTime }}
              </div>
            </div>
            <div v-else>立即发布</div>
          </div>
        </div>

        <div slot="operation" slot-scope="text, record">
          <a-space :size="1">
            <a
              @click="openCheckWritting(record)"
              v-show="record.publishStatus == 'UNPUBLISH' || record.publishStatus == 'EXPIRED'"
              >编辑</a
            >
            <a-divider
              type="vertical"
              v-show="record.publishStatus == 'UNPUBLISH' || record.publishStatus == 'EXPIRED'"
            />
            <a-dropdown placement="bottomCenter">
              <a class="ant-dropdown-link" @click="(e) => e.preventDefault()"> 更多 <a-icon type="down" /> </a>
              <a-menu slot="overlay">
                <a-menu-item v-show="record.publishStatus == 'UNPUBLISH'">
                  <a
                    v-clipboard:copy="record.sharePublishLink"
                    v-clipboard:success="onCopySuccess"
                    v-clipboard:error="onCopyError"
                    @click="
                      () =>
                        handleTrack('copy_share_link', {
                          note_id: record.id,
                        })
                    "
                    >复制分享链接</a
                  >
                </a-menu-item>
                <a-menu-item v-show="record.publishStatus == 'UNPUBLISH' || record.publishStatus == 'PUBLISHING'">
                  <a @click="updateLink(record.id)">设为"已发布"</a>
                </a-menu-item>
                <a-menu-item>
                  <a
                    v-clipboard:copy="onCopy(record)"
                    v-clipboard:success="onCopySuccess"
                    v-clipboard:error="onCopyError"
                    >复制ID</a
                  >
                </a-menu-item>
                <a-menu-item v-show="record.publishStatus !== 'PUBLISHED'">
                  <a @click="handleDel(record)">删除</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </a-space>
        </div>
      </a-table>
      <base-pagination
        :currentPage="pagination.current"
        :options="['15', '30', '50']"
        :pageSize="pagination.pageSize"
        :total="pagination.total"
        @onChange="handlePaginationChange"
        @onShowSizeChange="handlePaginationChange"
      />
      <!-- 查看/编辑抽屉 -->
      <writtingDrawer
        v-if="showDrawer"
        :limited="limited"
        :readOnly="true"
        @closeDrawer="handleDrawerClose"
        :pushId="pushId"
      />
      <!-- 查看头像大图 -->
      <preview-modal :visible="preview_visible" :preview-src="preview_src" width="50%" @cancel="handlePreviewCancel" />
      <!-- 填写链接 -->
      <setLinkModalComp ref="setLinkModalComp" @ok="getList()" />

      <!-- 删除 -->
      <a-modal title="确认删除" :visible="showDelModal" :width="360" @cancel="handleDelClose">
        <div style="margin: 15px 0">删除后风火轮App内不再展示，已生成的链接无法访问。</div>
        <div slot="footer" style="display: flex; align-items: center; justify-content: space-between">
          <div>
            <a-checkbox v-model="delParams.saveToCraft">保存内容到草稿</a-checkbox>
          </div>
          <div>
            <a-button @click="handleDelClose">取消</a-button>
            <a-button type="primary" :loading="delLoading" @click="handleDelConfirm">删除</a-button>
          </div>
        </div>
      </a-modal>
    </a-card>
    <detectionList ref="detectionList" v-show="currentStep === 2" @back="handleBack" />
  </div>
</template>
<script>
import api from '@/api/xhsAgencyApi';
import writtingDrawer from '@/components/xhsAgencyTool/writtingDrawer.vue';
import PreviewModal from '@/components/PreviewModalVideo/PreviewModalVideo';
import SearchItemList from '@/components/xhsAgencyTool/SearchItemList';
import setLinkModalComp from './components/setLinkModalComp.vue';
import detectionList from './detectionList.vue';
import { statusList, channelList, noteTypeList, columns } from './data';
import { trackRequest } from '@/utils/track';
import moment from 'moment';

export default {
  name: 'xhsNotePush',
  components: { writtingDrawer, PreviewModal, SearchItemList, setLinkModalComp, detectionList },
  data() {
    const personalityTypeList = [
      {
        label: '销售',
        value: 'SALE',
      },
      {
        label: '门店',
        value: 'STORE',
      },
    ];
    const personalityTypeObject = personalityTypeList.reduce((pre, cur) => ((pre[cur.value] = cur.label), pre), {});
    return {
      columns,
      statusList,
      channelList,
      noteTypeList,
      personalityTypeObject,
      searchForm: {
        status: undefined,
        memberParam: undefined,
        authorParam: undefined,
        noteParam: undefined,
        channel: undefined,
        noteType: 'ALL',
        orderByMap: {
          ctime: 'desc',
        },
        manayPublishStatus: 'ALL',
        publishChannel: 'ALL',
        searchType: 'NOTE',
        search: undefined,
        operatorId: undefined,
        timeRangeType: 'PUBLISH_TIME',
      },
      showDrawer: false,
      pushId: null,
      pagination: {
        current: 1,
        pageSize: 15,
        total: 1,
      },
      columns,
      tableLoading: false,
      dataList: [],
      preview_visible: false,
      preview_src: '',
      selectedRowKeys: [],
      showDelModal: false,
      delLoading: false,
      delParams: {
        id: undefined,
        saveToCraft: false,
      },
      checkTimeRange: [null, null],
      limited: false,

      allTeamList: [],
      allBrandList: [],
      allMemberList: [],
      showDot: false,
      expiredNum: 0,
      isExpired: false,
      currentStep: 1,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      await this.getAllMemberList();
      await this.getAllBrandList();

      const { limited, limitedName } = await this.checkLimit();
      this.limited = limited;
      if (this.limited) {
        const limitedNameList = [{ limitedName: '猛士运营', principalName: '猛士科技' }];
        const limitedBrandName = limitedNameList.find((v) => v.limitedName === limitedName)?.principalName;
        const f = this.allBrandList.find((v) => v.principalName === limitedBrandName);
        if (f?.principalId) {
          this.searchForm.principalId = f.principalId;
        }
      }

      if (this.$route.query.pushId) {
        this.searchForm.pushId = this.$route.query.pushId;
        this.$router.replace({ name: 'xhsNotePush' }, () => {
          this.getList();
          if (this.searchForm.pushId) delete this.searchForm.pushId;
        });
        return;
      }
      this.getOperatorTeamList(this.searchForm.principalId);

      const currentUser = this.allMemberList.find((v) => v.operatorUsername === this.$router.app.user.username);
      if (currentUser?.id) this.searchForm.operatorId = currentUser.id;
      this.getList();
    },
    async checkError() {
      const { code, data, message } = await api.checkPushResult();
      if (code === 200) {
        this.showDot = data ? true : false;
      } else {
        this.$message.error(message);
      }
    },
    async onTimeChange(dates) {
      this.checkTimeRange = [dates[0], dates[1]];
      this.getList();
    },
    onTableChange(pagination, filters, sorter) {
      const temp = sorter.order === 'descend' ? 'desc' : sorter.order === 'ascend' ? 'asc' : undefined;
      this.searchForm.orderByMap =
        sorter.field === 'ctime'
          ? {
              ctime: temp,
            }
          : {
              publishTime: temp,
            };
      this.getList();
    },
    handlePreviewCancel() {
      this.preview_src = '';
      this.preview_visible = false;
    },
    openBigImg(url) {
      if (!url) {
        return this.$message.error('URL为空，不支持查看！');
      }
      this.width = '480px';
      this.preview_visible = true;
      this.preview_src = url;
    },
    onCopy(record) {
      const copyText = record.publishStatus === 'PUBLISHED' ? record.noteId : record.id;
      return copyText;
    },
    onCopySuccess() {
      this.$message.success('复制成功');
    },
    onCopyError(e) {
      if (!e.text) {
        this.$message.error('复制失败，复制文本为空');
      } else {
        this.$message.error('复制失败，该浏览器不支持自动复制');
      }
    },

    async getList(initPage = false) {
      this.checkError();
      if (initPage) this.pagination.current = 1;
      const startTime = this.checkTimeRange[0]
        ? `${moment(this.checkTimeRange[0]).format('YYYY-MM-DD')} 00:00:00`
        : undefined;
      const endTime = this.checkTimeRange[1]
        ? `${moment(this.checkTimeRange[1]).format('YYYY-MM-DD')} 23:59:59`
        : undefined;
      const param = {
        ...this.searchForm,
        startTime,
        endTime,
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize,
        expiredStatus: '',
      };
      param.manayPublishStatus = param.manayPublishStatus == 'ALL' ? '' : param.manayPublishStatus;
      param.publishChannel = param.publishChannel == 'ALL' ? '' : param.publishChannel;
      param.noteType = param.noteType == 'ALL' ? '' : param.noteType;
      param.manayPublishStatus = param.manayPublishStatus ? [this.searchForm.manayPublishStatus] : [];
      if (this.isExpired) {
        param.manayPublishStatus = [];
        param.expiredStatus = 'EXPIRED';
      }
      this.tableLoading = true;
      await this.handleRequest(
        'pushPageApi',
        (data) => {
          this.getStaticList(param);
          this.dataList =
            data.list?.map((v) => {
              let previewUrl = '';
              if (v.materialArr?.length > 0 && v.materialArr[0].previewUrl) {
                // oss图片
                if (
                  v.materialArr[0].previewUrl.indexOf('snapshot') == -1 &&
                  (v.materialArr[0].previewUrl.indexOf('content') > -1 ||
                    v.materialArr[0].previewUrl.indexOf('afanti') > -1)
                ) {
                  if (v.materialArr[0].previewUrl.indexOf('x-oss-process') > -1) {
                    previewUrl = v.materialArr[0].previewUrl += `/resize,l_120,m_lfit`;
                  } else {
                    previewUrl = v.materialArr[0].previewUrl += `?x-oss-process=image/resize,l_120,m_lfit`;
                  }
                } else {
                  previewUrl = v.materialArr[0].previewUrl;
                }
              }
              return { ...v, previewUrl };
            }) || [];
          this.pagination.total = data.total;
        },
        param
      ).finally(() => {
        this.tableLoading = false;
      });
    },
    async getStaticList(params) {
      const { code, data, message } = await api.getPushDataStatistics(params);
      if (code === 200) {
        const {
          noteTypeStatisticList,
          publishChannelStatisticList,
          publishStatusStatisticList,
          expiredStatusStatisticList,
        } = data;
        this.expiredNum = expiredStatusStatisticList?.find((v) => v.status === 'EXPIRED')?.count || 0;
        this.noteTypeList.forEach((item) => {
          noteTypeStatisticList.forEach((s) => {
            if (s.status === item.value) {
              item.number = s.count;
            }
          });
        });
        this.channelList.forEach((item) => {
          publishChannelStatisticList.forEach((s) => {
            if (s.status === item.value) {
              item.number = s.count;
            }
          });
        });
        this.statusList.forEach((item) => {
          publishStatusStatisticList.forEach((s) => {
            if (s.status === item.value) {
              item.number = s.count;
            }
          });
        });
      } else {
        this.$message.error(message);
      }
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getList();
    },
    async handleRequest(APINAME, callbackFn, PARAMS = null) {
      const { code, data, message } = await api[APINAME](PARAMS);
      if (code === 200) {
        callbackFn(data);
      } else {
        return this.$message.error(`${message}`);
      }
    },
    transStatusData(val) {
      const data = this.statusList.find((status) => status.value == val);
      return { text: data.text || '-', color: data.color || '#333' };
    },
    // 小红书主页跳转
    async handleJumpXHS(value) {
      const hide = this.$message.loading('查询中...', 0);
      await this.handleRequest(
        'getXhsAccountPage',
        (data) => {
          window.open(data, '_blank');
        },
        {
          authorId: value.authorId,
        }
      ).finally(() => hide());
    },
    openCheckWritting(value) {
      if (!value.id) return;
      const { href } = this.$router.resolve({ name: 'articleDetail', query: { pushId: value.id } });
      window.open(href, '_blank');
      this.handleTrack('edit_share_note', {
        note_id: value.id,
      });
    },
    updateLink(note_id) {
      this.$refs.setLinkModalComp.handleOpen(note_id);
      this.handleTrack('add_note_link', {
        note_id,
      });
    },
    openDrawer(value) {
      if (!value.id) return;
      this.showDrawer = true;
      this.pushId = value.id;
    },
    handleDrawerClose() {
      this.showDrawer = false;
      this.pushId = undefined;
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },

    handleTrack(trackEvent, trackParams = {}) {
      const to = this.$route;
      trackRequest(to, trackEvent, trackParams);
    },
    handleDel({ id }) {
      this.showDelModal = true;
      this.delParams.id = id;
    },
    async handleDelConfirm() {
      this.delLoading = true;
      const { code, message } = await api
        .deleteXhsPromotionInfoRemark(this.delParams.id, {
          saveToCraft: this.delParams.saveToCraft,
        })
        .finally(() => (this.delLoading = false));
      if (code === 200) {
        this.getList();
        this.$message.success('删除成功');
        this.handleDelClose();
      } else {
        this.$message.error(message);
      }
    },
    handleDelClose() {
      this.showDelModal = false;
      this.delParams = this.$options.data().delParams;
    },
    checkLimit() {
      return new Promise((resolve) => {
        let limited = false;
        const limitedRoleList = ['猛士运营'];
        let limitedName = '';
        this.$router.app.user.roles.forEach((v) => {
          if (limitedRoleList.indexOf(v.name) > -1) {
            limited = true;
            limitedName = v.name;
            return;
          }
        });
        resolve({ limited, limitedName });
      });
    },

    handleBrandClick(value) {
      if (this.limited) return;
      this.searchForm.principalId = value;
      this.searchForm.operatorGroupId = undefined;
      this.allTeamList = [];
      this.getOperatorTeamList(value);
      this.getList(true);
    },
    async getOperatorTeamList(principalId = undefined) {
      const { code, message, data } = await api.getTeamManageList({ page: 1, size: 999, principalId });
      if (code === 200) {
        this.allTeamList = data.list;
      } else {
        this.$message.error(message);
      }
    },
    async getAllBrandList() {
      return new Promise(async (resolve) => {
        const { code, data, message } = await api.getBrandManageList({ size: 999, page: 1 });
        if (code === 200) {
          this.allBrandList = data.list.filter((v) => v.status === 'NORMAL');
        } else {
          this.$message.error(message);
        }
        resolve();
      });
    },
    async getAllMemberList() {
      const { code, data, message } = await api.getTeamMember({ page: 1, size: 999, isDeleted: 0 });
      if (code === 200) {
        this.allMemberList = data.list;
      } else {
        this.$message.error(message);
      }
    },
    handleStatusClick() {
      this.isExpired = false;
      this.getList(true);
    },
    handleExpiredClick(isExpired) {
      this.searchForm.manayPublishStatus = 'UNPUBLISH';
      this.isExpired = isExpired;
      this.getList(true);
    },
    handleJumpToError() {
      this.currentStep = 2;
      this.$refs.detectionList.init();
    },
    handleBack() {
      this.currentStep = 1;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.search {
  margin-bottom: 8px;
}
.media-column {
  display: flex;
  margin-top: 10px;
  text-align: left;

  .media-left {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .media-right {
    flex: 1;

    .right-txt {
      display: flex;
      justify-content: start;
      align-items: center;

      .logo {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .right-id {
      width: 140px;
      margin-right: 10px;
      flex: 1;
      display: flex;
      align-items: center;
    }
    .avatar-left {
      width: 30px;
      height: 30px;
      margin-right: 6px;
      overflow: hidden;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .avatar-right {
      flex: 1;
      display: flex;
      align-items: center;
    }
  }

  .avatar-right {
    flex: 1;
    display: flex;
    align-items: center;
  }
}
.writtingPreview {
  display: flex;

  .previewLeft {
    flex-shrink: 0;
    margin-right: 5px;
    width: 60px;
    height: 80px;
    background-size: cover;
    background-position: center;
    cursor: pointer;
  }

  .previewRight {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    .rightTitle {
      width: 180px;
      color: #000;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
    }
    .rightXhs {
      display: flex;
      justify-content: start;
      align-items: center;

      .logo {
        width: 20px;
        height: 20px;
        overflow: hidden;
        border-radius: 10px;
        margin: 0;
        margin-right: 5px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .xhsId {
        flex: 1;
        max-width: 145px;
        color: #7f7f7f;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
      }
    }
    .nicknameLine {
      display: flex;
      align-items: center;
    }
  }
}

.blue-text {
  color: #1890ff !important;
  cursor: pointer;
}

.red-text {
  color: #e65a6b;
}

.search_list {
  margin: 10px 0;
  .search_brand {
    .brand_item {
      color: #adadad;
      padding: 5px 10px;
      border: 1px solid #bfbfbf;
      cursor: pointer;

      &:first-child {
        border-radius: 6px 0 0 6px;
      }

      &:last-child {
        border-radius: 0 6px 6px 0;
      }
    }
  }

  /deep/ .ant-input-group {
    width: unset;
  }
}

.search_brand {
  .all_item {
    color: #adadad;
    padding: 5px 10px;
    border: 1px solid #bfbfbf;
    cursor: pointer;
    border-radius: 6px;
  }

  .brand_item {
    color: #adadad;
    padding: 5px 10px;
    border: 1px solid #bfbfbf;
    cursor: pointer;
    background-color: #fff;
    line-height: 30px;

    &:first-child {
      border-radius: 6px 0 0 6px;
    }

    &:last-child {
      border-radius: 0 6px 6px 0;
    }

    &.active_item {
      border: 1px solid #1890ff;
      background-color: #1890ff;
      color: #fff;
    }
  }
}

/deep/ .ant-badge-dot {
  width: 12px;
  height: 12px;
  left: 0;
  transform: translate(-50%, -50%);
}

.active_expired_item {
  color: #1890ff;
}
</style>
