<template>
  <a-modal title="设为“已发布”" :visible="showLinkModal" @cancel="handleLinkClose" @close="handleLinkClose">
    <div>后续将持续更新笔记数据，详见数据中心</div>
    <div style="text-align: right">
      <a-button type="link" :loading="searchNoteLoading" @click="getOriginNote">获取笔记</a-button>
    </div>
    <a-input-search placeholder="填入小红书笔记链接" allowClear v-model="updateLinkData.noteParam" @search="getOriginNote"></a-input-search>
    <div class="noteLine" v-show="searchNoteLoading">
      <a-icon type="loading" style="margin: 0 10px" />
      <div>笔记获取中...</div>
    </div>
    <div class="noteLine" v-show="showNoteFail">
      <div>笔记获取失败，请重试</div>
    </div>
    <div class="writtingPreview" style="margin: 10px 0" v-if="noteDetail.noteId">
      <div class="previewLeft">
        <img
          style="width: 100%; height: 100%; object-fit: cover; cursor: default"
          referrerpolicy="no-referrer"
          :src="noteDetail.materialArr[0].previewUrl"
        />
      </div>
      <div class="previewRight">
        <div class="rightTitle" style="width: 400px; cursor: text" :title="noteDetail.noteTitle">
          {{ noteDetail.noteTitle }}
        </div>
        <div class="nicknameLine">
          <img
            style="width: 20px; height: 20px; border-radius: 3px; object-fit: cover; margin-right: 5px"
            src="@/assets/icon/xhs_logo.png"
            alt=""
          />
          <div class="rightIdL">{{ noteDetail.nickname }}</div>
        </div>
      </div>
    </div>
    <div slot="footer">
      <a-button @click="handleLinkClose">取消</a-button>
      <a-button type="primary" :disabled="!noteDetail.noteId" :loading="confirmLoading" @click="handleLinkOk"
        >确定</a-button
      >
    </div>
  </a-modal>
</template>

<script>
import api from '@/api/xhsAgencyApi';

export default {
  name: '',
  props: {},
  components: {},
  data() {
    return {
      showLinkModal: false,
      confirmLoading: false,
      updateLinkData: {
        id: null,
        noteParam: null,
      },
      noteDetail: {
        noteId: undefined,
      },
      showNoteFail: false,
      searchNoteLoading: false,
    };
  },
  methods: {
    handleOpen(id) {
      this.showLinkModal = true;
      this.updateLinkData.id = id;
    },
    handleLinkClose() {
      this.showLinkModal = false;
      this.updateLinkData = {
        id: null,
        noteParam: null,
      };
      this.showNoteFail = false;
      this.searchNoteLoading = false;
      this.noteDetail = {};
    },
    async handleLinkOk() {
      this.confirmLoading = true;
      this.handleRequest(
        'handleUpdatePushLink',
        () => {
          this.$message.success('操作成功');
          this.handleLinkClose();
          this.$emit('ok');
        },
        { ...this.updateLinkData }
      ).finally(() => (this.confirmLoading = false));
    },
    getOriginNote() {
      if (!this.updateLinkData.noteParam) return this.$message.error('请输入笔记链接');
      this.showNoteFail = false;
      this.searchNoteLoading = true;
      this.noteDetail = {};
      api
        .getOriginXhsNote({ noteParam: this.updateLinkData.noteParam })
        .then(({ code, data, message }) => {
          if (code == 200) {
            if (!data.length) return (this.showNoteFail = true);
            this.noteDetail = data[0];
          } else {
            this.$message.error(message);
            this.showNoteFail = true;
          }
        })
        .catch(() => (this.showNoteFail = true))
        .finally(() => (this.searchNoteLoading = false));
    },
    async handleRequest(APINAME, callbackFn, PARAMS = null) {
      const { code, data, message } = await api[APINAME](PARAMS);
      if (code === 200) {
        callbackFn(data);
      } else {
        return this.$message.error(`${message}`);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.writtingPreview {
  display: flex;

  .previewLeft {
    flex-shrink: 0;
    margin-right: 5px;
    width: 60px;
    height: 80px;
    background-size: cover;
    background-position: center;
    cursor: pointer;
  }

  .previewRight {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    .rightTitle {
      width: 180px;
      color: #000;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
    }
    .rightXhs {
      display: flex;
      justify-content: start;
      align-items: center;

      .logo {
        width: 20px;
        height: 20px;
        overflow: hidden;
        border-radius: 10px;
        margin: 0;
        margin-right: 5px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .xhsId {
        flex: 1;
        max-width: 145px;
        color: #7f7f7f;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
      }
    }
    .nicknameLine {
      display: flex;
      align-items: center;
    }
  }
}

.noteLine {
  display: flex;
  align-items: center;
  margin: 10px 0;
  padding: 0 10px;
  height: 70px;
  background-color: #f2f2f2;
}
</style>
