<template>
  <a-card>
    <div class="detection-list">
      <a-alert type="warning" show-icon message="系统会自动检测发布中的笔记ID，遇到异常情况需要人工介入时在此处理" />
      <div style="margin-top: 5px"><a-button @click="handleBack">返回</a-button></div>
      <div class="search_list">
        <div>
          <a-space>
            <div class="search_brand">
              <span
                :class="['brand_item', !searchForm.principalId ? 'active_item' : '']"
                @click="handleBrandClick(undefined)"
                >全部品牌</span
              >
              <span
                :class="['brand_item', searchForm.principalId === item.principalId ? 'active_item' : '']"
                v-for="item in allBrandList"
                :key="item.principalId"
                @click="handleBrandClick(item.principalId)"
                >{{ item.principalName }}</span
              >
              <span
                :class="['brand_item', searchForm.principalId === -1 ? 'active_item' : '']"
                @click="handleBrandClick(-1)"
                >无品牌</span
              >
            </div>
            <a-select
              style="width: 200px"
              v-model="searchForm.operatorGroupId"
              placeholder="请选择团队"
              show-search
              option-filter-prop="children"
              allowClear
              @change="getList(true)"
            >
              <a-select-option v-for="item in allTeamList" :key="item.id" :value="item.id">{{
                item.groupName
              }}</a-select-option>
            </a-select>
          </a-space>
        </div>
        <div style="display: flex; justify-content: space-between; margin-top: 10px">
          <div style="display: flex; width: 900px">
            <a-input-group compact>
              <a-select
                default-value="NOTE"
                style="width: 130px"
                v-model="searchForm.searchType"
                @change="searchForm.search = undefined"
              >
                <a-select-option value="NOTE"> 笔记标题 </a-select-option>
                <a-select-option value="AUTHOR"> 昵称/小红书号 </a-select-option>
                <a-select-option value="MEMBER"> 成员名称/编码 </a-select-option>
              </a-select>
              <a-input-search
                style="width: 180px"
                placeholder="搜索"
                v-model.trim="searchForm.search"
                @search="getList(true)"
                allowClear
              ></a-input-search>
            </a-input-group>

            <a-select
              v-model="searchForm.operatorId"
              style="width: 360px; margin: 0px 10px 0 -85px"
              placeholder="运营人"
              show-search
              option-filter-prop="children"
              @change="getList(true)"
              allowClear
            >
              <a-select-option v-for="item in allMemberList" :key="item.id" :value="item.id">{{
                item.operatorName
              }}</a-select-option>
            </a-select>

            <a-input-group compact>
              <a-select
                default-value="PUBLISH_TIME"
                style="width: 125px"
                v-model="searchForm.timeRangeType"
                @change="checkTimeRange = [null, null]"
              >
                <a-select-option value="PUBLISH_TIME"> 发布时间 </a-select-option>
                <a-select-option value="PUSH_TIME"> 推送时间 </a-select-option>
              </a-select>
              <a-range-picker
                style="width: 220px"
                :placeholder="['开始时间', '结束时间']"
                :value="checkTimeRange"
                @change="onTimeChange"
                allowClear
              />
            </a-input-group>
          </div>
          <div>
            <a-tooltip placement="left">
              <template slot="title">
                <div>检测规则</div>
                <div>1. 用户在风火轮或分享链接中点击“已发布”会立即尝试检测</div>
                <div>2. 每日0点自动检测所有“发布中”笔记的发布结果</div>
                <div>3. 以账号下最新的5条笔记，根据标题精确匹配，改了标题就识别不到</div>
                <div>4. 因平台限制，定时发布的笔记未到时间时可能检测不到，可等待下次检测或人工设置“已发布”</div>
                <div>5. 首次检测7日后仍未检测到，停止系统检测，需人工设置“已发布”</div>
              </template>
              <a-icon type="question-circle" />
            </a-tooltip>
            自动检测说明
          </div>
        </div>
      </div>
      <a-table
        :loading="tableLoading"
        :rowKey="(record, index) => index"
        :columns="columns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ x: 'auto' }"
      >
        <div slot="writtingPreview" slot-scope="text, record">
          <div class="writting-preview">
            <div class="preview-left" @click="openDrawer(record)">
              <img
                v-if="record.previewUrl"
                style="height: 100%; width: 100%; object-fit: cover"
                referrerpolicy="no-referrer"
                :src="record.previewUrl"
              />
            </div>
            <div class="preview-right">
              <div class="right-title" @click="openDrawer(record)">
                <a-popover>
                  <template slot="content">
                    <p>{{ record.noteTitle ? record.noteTitle : '' }}</p>
                  </template>
                  {{ record.noteTitle ? record.noteTitle : '-' }}
                </a-popover>
              </div>
              <template v-if="record.topicSelectionTagList && record.topicSelectionTagList.length > 0">
                <div>
                  <a-tag
                    :color="record.topicSelectionTagList[0] ? record.topicSelectionTagList[0].colorHex : 'blue'"
                    style="white-space: break-spaces"
                    >{{ record.topicSelectionTagList[0] ? record.topicSelectionTagList[0].tagName : '-' }}</a-tag
                  >
                  <a-tag v-if="record.topicSelectionTagList.length > 1" color="blue"
                    >+{{ record.topicSelectionTagList.length - 1 }}</a-tag
                  >
                </div>
              </template>
              <div class="right-xhs" v-show="record.nickname" style="display: flex" @click="handleJumpXHS(record)">
                <div class="logo">
                  <img src="@/assets/icon/xhs_logo.png" alt="" />
                </div>
                <div class="xhs-id blue-text">{{ record.nickname || '-' }}</div>
              </div>
            </div>
          </div>
        </div>
        <div slot="detectMsg" slot-scope="text, record" style="text-align: left; color: #5a5a5a">
          <div v-show="record.detectStatus === 'MULTIPLE_NOTE'">
            <div>检测到多条笔记</div>
            <div>请门店先隐藏或删除多余的笔记，等待下次自动检测或人工设为“已发布”</div>
          </div>
          <div v-show="record.detectStatus === 'NOT_DETECT'">
            <div>未检测到笔记，可能原因：</div>
            <div>1. 没有发或发到了别的账号</div>
            <div>2. 修改了标题</div>
          </div>
        </div>
        <div slot="noteIdArr" slot-scope="text, record">
          <template v-if="record.detectStatus === 'MULTIPLE_NOTE'">
            <div v-for="(v, i) in record.noteIdArr" :key="i">{{ v }}</div>
          </template>
          <template v-else> - </template>
        </div>
        <div slot="carSeries" slot-scope="text, record">
          <div>{{ record.principalName || '-' }}</div>
          <div>{{ record.carSeriesName || '-' }}</div>
        </div>
        <div slot="publishTime" slot-scope="text, record">
          <div v-if="record.status == 'PUBLISHED' || record.status == 'PUBLISHED_BY_LINK'">
            {{ record.publishTime || '-' }}
          </div>
          <div v-else>
            <div v-if="record.postTime">
              <div style="font-size: 12px; user-select: none"><a-icon type="clock-circle" />定时发布</div>
              <div>
                {{ record.postTime }}
              </div>
            </div>
            <div v-else>立即发布</div>
          </div>
        </div>

        <div slot="operation" slot-scope="text, record">
          <a-button type="link" @click="updateLink(record.xhsNotePushId)">设为"已发布"</a-button>
        </div>
      </a-table>
      <base-pagination
        :currentPage="pagination.current"
        :options="['15', '30', '50']"
        :pageSize="pagination.pageSize"
        :total="pagination.total"
        @onChange="handlePaginationChange"
        @onShowSizeChange="handlePaginationChange"
      />

      <!-- 查看/编辑抽屉 -->
      <writtingDrawer v-if="showDrawer" :readOnly="true" @closeDrawer="handleDrawerClose" :pushId="pushId" />
      <!-- 填写链接 -->
      <setLinkModalComp ref="setLinkModalComp" @ok="getList()" />
    </div>
  </a-card>
</template>

<script>
import moment from 'moment';
import writtingDrawer from '@/components/xhsAgencyTool/writtingDrawer.vue';
import setLinkModalComp from './components/setLinkModalComp.vue';
import { detectColumns as columns } from './data';
import api from '@/api/xhsAgencyApi';

export default {
  name: 'detectionList',
  props: {},
  components: { writtingDrawer, setLinkModalComp },
  data() {
    return {
      pagination: {
        current: 1,
        pageSize: 15,
        total: 1,
      },
      columns,
      tableLoading: false,
      dataList: [],
      searchForm: {
        status: undefined,
        noteType: 'ALL',
        manayPublishStatus: 'ALL',
        publishChannel: 'ALL',
        searchType: 'NOTE',
        search: undefined,
        operatorId: undefined,
        timeRangeType: 'PUBLISH_TIME',
      },
      checkTimeRange: [null, null],
      allTeamList: [],
      allBrandList: [],
      allMemberList: [],
      showDrawer: false,
      pushId: null,
    };
  },
  methods: {
    async init() {
      this.getAllBrandList();
      this.getOperatorTeamList();
      await this.getAllMemberList();
      const currentUser = this.allMemberList.find((v) => v.operatorUsername === this.$router.app.user.username);
      if (currentUser?.id) this.searchForm.operatorId = currentUser.id;
      this.getList(true);
    },
    async getList(initPage = false) {
      if (initPage) this.pagination.current = 1;
      const startTime = this.checkTimeRange[0]
        ? `${moment(this.checkTimeRange[0]).format('YYYY-MM-DD')} 00:00:00`
        : undefined;
      const endTime = this.checkTimeRange[1]
        ? `${moment(this.checkTimeRange[1]).format('YYYY-MM-DD')} 23:59:59`
        : undefined;
      const param = {
        ...this.searchForm,
        startTime,
        endTime,
        page: this.pagination.current,
        size: this.pagination.pageSize,
      };
      param.manayPublishStatus = param.manayPublishStatus == 'ALL' ? '' : param.manayPublishStatus;
      param.publishChannel = param.publishChannel == 'ALL' ? '' : param.publishChannel;
      param.noteType = param.noteType == 'ALL' ? '' : param.noteType;
      param.manayPublishStatus = param.manayPublishStatus ? [this.searchForm.manayPublishStatus] : [];
      this.tableLoading = true;
      await this.handleRequest(
        'getPushResultList',
        (data) => {
          this.dataList = data.list.map((v) => {
            v.previewUrl = undefined;
            if (v.materialArr && v.materialArr.length > 0) {
              v.previewUrl = v.materialArr[0].previewUrl || undefined;
            }
            return v;
          });
          this.pagination.total = data.total;
        },
        param
      ).finally(() => {
        this.tableLoading = false;
      });
    },
    handleBrandClick(value) {
      if (this.limited) return;
      this.searchForm.principalId = value;
      this.searchForm.operatorGroupId = undefined;
      this.allTeamList = [];
      this.getOperatorTeamList(value);
      this.getList(true);
    },
    async getOperatorTeamList(principalId = undefined) {
      const { code, message, data } = await api.getTeamManageList({ page: 1, size: 999, principalId });
      if (code === 200) {
        this.allTeamList = data.list;
      } else {
        this.$message.error(message);
      }
    },
    async onTimeChange(dates) {
      this.checkTimeRange = [dates[0], dates[1]];
      this.getList();
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getList();
    },
    async getAllBrandList() {
      return new Promise(async (resolve) => {
        const { code, data, message } = await api.getBrandManageList({ size: 999, page: 1 });
        if (code === 200) {
          this.allBrandList = data.list.filter((v) => v.status === 'NORMAL');
        } else {
          this.$message.error(message);
        }
        resolve();
      });
    },
    async getAllMemberList() {
      const { code, data, message } = await api.getTeamMember({ page: 1, size: 999, isDeleted: 0 });
      if (code === 200) {
        this.allMemberList = data.list;
      } else {
        this.$message.error(message);
      }
    },
    openDrawer(value) {
      if (!value.xhsNotePushId) return;
      this.showDrawer = true;
      this.pushId = value.xhsNotePushId;
    },
    handleDrawerClose() {
      this.showDrawer = false;
      this.pushId = undefined;
    },
    updateLink(xhsNotePushId) {
      this.$refs.setLinkModalComp.handleOpen(xhsNotePushId);
    },
    handleBack() {
      Object.assign(this.$data, this.$options.data());
      this.$emit('back');
    },
    async handleRequest(APINAME, callbackFn, PARAMS = null) {
      const { code, data, message } = await api[APINAME](PARAMS);
      if (code === 200) {
        callbackFn(data);
      } else {
        return this.$message.error(`${message}`);
      }
    },
    // 小红书主页跳转
    async handleJumpXHS(value) {
      const hide = this.$message.loading('查询中...', 0);
      await this.handleRequest(
        'getXhsAccountPage',
        (data) => {
          window.open(data, '_blank');
        },
        {
          authorId: value.authorId,
        }
      ).finally(() => hide());
    },
  },
};
</script>

<style lang="less" scoped>
.search_list {
  margin: 10px 0;
  .search_brand {
    .brand_item {
      color: #adadad;
      padding: 5px 10px;
      border: 1px solid #bfbfbf;
      cursor: pointer;

      &:first-child {
        border-radius: 6px 0 0 6px;
      }

      &:last-child {
        border-radius: 0 6px 6px 0;
      }
    }
  }
}

.search_brand {
  .all_item {
    color: #adadad;
    padding: 5px 10px;
    border: 1px solid #bfbfbf;
    cursor: pointer;
    border-radius: 6px;
  }

  .brand_item {
    color: #adadad;
    padding: 5px 10px;
    border: 1px solid #bfbfbf;
    cursor: pointer;
    background-color: #fff;
    line-height: 30px;

    &:first-child {
      border-radius: 6px 0 0 6px;
    }

    &:last-child {
      border-radius: 0 6px 6px 0;
    }

    &.active_item {
      border: 1px solid #1890ff;
      background-color: #1890ff;
      color: #fff;
    }
  }
}

.writting-preview {
  display: flex;

  .preview-left {
    flex-shrink: 0;
    margin-right: 5px;
    width: 60px;
    height: 80px;
    cursor: pointer;
    background: #cccccc82;
  }

  .preview-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    .right-title {
      width: 180px;
      color: #000;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
    }
    .right-xhs {
      display: flex;
      justify-content: start;
      align-items: center;

      .logo {
        width: 20px;
        height: 20px;
        overflow: hidden;
        border-radius: 10px;
        margin: 0;
        margin-right: 5px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .xhs-id {
        flex: 1;
        max-width: 145px;
        color: #7f7f7f;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
      }
    }
    .nicknameLine {
      display: flex;
      align-items: center;
    }
  }
}

.blue-text {
  color: #1890ff !important;
  cursor: pointer;
}
</style>
